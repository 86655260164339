<script setup>
import WebDevelopment from './views/WebDevelopment.vue';
import {onBeforeMount} from "vue";

onBeforeMount(() => {
  let lang = localStorage.getItem("lang");
  if (!lang) {
    localStorage.setItem("lang", "es");
  }

  window.changeLang = (lang) => {
    localStorage.setItem("lang", lang);
    location.reload();
  }
})
</script>


<template>
<WebDevelopment/>
</template>


<style>
</style>
