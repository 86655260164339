const traducciones = {
    en: {
        biografia: {
            name: "Biography",
            content: "ResiCorporation is a leader in web development for Spanish-speaking and English-speaking markets. We create and maintain exceptional, optimised, and secure websites."
        },
        contacto:{
            name:"Contact",

        },
        servicios:{
            name: "Services",
            content: "Web Development",
            contentServicios: "What we offer",
            desarrolloWeb: "Web Development",
            contentDesarrollo: "Creation of your website from scratch or based on an existing model",
            mantenimiento: "Maintenance",
            contentMantenimiento: "Service that provides the best quality and upon request, with professional work and customer service.",
            servidor: "Server",
            contentServidor: "Server provided by us for hosting your website"
        },
        proyectos:{
            name: "Completed Projects",
            content: "+12",
            nameProyecto: "Projects",
            nuestrosProyectos: "Our Projects",
            verSitio: "View Site"
        },
        idiomas:{
            name: "Languages",
            content: "Spanish and English"
        },
        titulo:{
            name: "Welcome to Resi Corporation"
        },
        tituloServicio:{
            name: "Contact Us",
            content: "Get in touch"
        },
        hablanos:{
            name: "Talk to Us"
        },
        escribenos:{
            name: "Write to Us",
            content: "Tell us about your project"
        },
        formulario:{
            name: "Full Name",
            contentName: "Enter your full name",
            telefono: "Phone",
            contentTelefono: "Enter your phone number",
            contentEmail: "Enter your email",
            proyecto: "Project",
            contentProyecto: "Describe what you are looking for...",
            enviar: "Send"
        },
        desarrollo:{
            habilidades: "Skills",
            nuestrasHabilidades: "Our Skills English",
            front: "Front-end Development",
            back: "Back-end development"
        },
        inicio:{name:"Home"}
    },
    es: {
        biografia: {
            name: "Biografia",
            content: "ResiCorporation es líder en desarrollo web para mercados hispanohablantes y anglosajones. Creamos y mantenemos sitios web excepcionales, optimizados y seguros."
        },
        contacto:{
            name:"Contacto",

        },
        servicios:{
            name:"Servicios",
            content:"Creación de Páginas Web",
            contentServicios:"Que ofrecemos",
            desarrolloWeb:"Desarrollo Web",
            contentDesarrollo:"Creación de su sitio web desde cero o basado en un modelo existente",
            mantenimiento:"Mantenimiento",
            contentMantenimiento:"Servicio que brinda la mejor calidad y a pedido del cliente, con trabajo profesional y atención al cliente.",
            servidor:"Servidor",
            contentServidor:"Servidor proporcionado por nosotros para alojar su sitio web"
        },
        proyectos:{
            name:"Proyectos Completos",
            content:"+12",
            nameProyecto:"Proyectos",
            nuestrosProyectos:"Nuestros proyectos",
            verSitio:"Ver Sitio"

        },
        idiomas:{
            name:"Idiomas",
            content:"Español e Ingles"
        },
        titulo:{
            name:"Bienvenido a Resi Corporation"
        },
        tituloServicio:{
            name:"Contactanos",
            content:"Ponte en contacto"
        },
        hablanos:{
            name:"Hablanos",
        },
        escribenos:{
            name:"Escribenos",
            content:"Escríbenos tu proyecto"
        },
        formulario:{
            name:"Nombre Completo",
            contentName:"Escribe tu nombre completo",
            telefono:"Teléfono",
            contentTelefono:"Escribe tu teléfono",
            contentEmail:"Escribe tu email",
            proyecto:"Proyecto",
            contentProyecto:"Describe lo que buscas... ",
            enviar:"Enviar"
        },
        desarrollo:{
            habilidades:"Habilidades",
            nuestrasHabilidades:"Nuestas Habilidades",
            front:"Desarrollo Front-end",
            back:"Desarrollo Back-end",
        },
        inicio:{name:"Inicio"}

    }
}

const lang = localStorage.getItem("lang");

export const translate = (word) => {
    return traducciones?.[lang]?.[word] ?? word;
}