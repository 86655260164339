<script setup>
import {translate} from "../../public/translations.js";

const imagesFront = [
  {id:"HTML",src:"/img/html-1.svg", alt:"Resi Corporation HTML", title: "Resi Corporation HTML"},
  {id:"CSS",src:"/img/css-3.svg", alt:"Resi Corporation CSS", title: "Resi Corporation CSS"},
  {id:"JavaScript",src:"/img/logo-javascript.svg", alt:"Resi Corporation JavaScript", title: "Resi Corporation JavaScript"},
  {id:"TypeScript",src:"/img/typescript.svg", alt:"Resi Corporation TypeScript", title: "Resi Corporation TypeScript"},
  {id:"React",src:"/img/react-2.svg", alt:"Resi Corporation React", title: "Resi Corporation React"},
  {id:"Vue.js",src:"/img/Vue.js_Logo_2.svg", alt:"Resi Corporation Vue.js", title: "Resi Corporation Vue.js"},
  {id:"Bootstrap",src:"/img/bootstrap-5-1.svg", alt:"Resi Corporation Bootstrap", title: "Resi Corporation Bootstrap"},
  {id:"Sass",src:"/img/sass-1.svg", alt:"Resi Corporation Sass", title: "Resi Corporation Sass"},
];

const imagesBack = [
  {id:"PHP",src:"/img/PHP-logo.svg", alt:"Resi Corporation PHP", title: "Resi Corporation PHP"},
  {id:"Python",src:"/img/python-4.svg", alt:"Resi Corporation Python", title: "Resi Corporation Python"},
  {id:"MySQL",src:"/img/mysql-logo.svg", alt:"Resi Corporation MySQL", title: "Resi Corporation MySQL"},
  {id:"NestJS",src:"/img/nestjs.svg", alt:"Resi Corporation NestJS", title: "Resi Corporation NestJS"},
  {id:"MongoDB",src:"/img/mongodb-icon-2.svg", alt:"Resi Corporation MongoDB", title: "Resi Corporation MongoDB"},
  {id:"Next.js",src:"/img/next-js.svg", alt:"Resi Corporation Next.js", title: "Resi Corporation Next.js"}
];
</script>


<template>

  <section class="skills section" id="skills">
    <h2 class="section__title">{{ translate("desarrollo").habilidades}}</h2>
    <span class="section__subtitle">{{ translate("desarrollo").nuestrasHabilidades}}</span>

    <div class="skills__container container grid section__border">
      <div class="skills__content">
        <h3 class="skills__title">
          <i class="ri-braces-line"></i> {{ translate("desarrollo").front}}
        </h3>

        <div class="skills__info">
          <div class="skills__data" v-for="image in imagesFront">
            <div class="skills__blob">
              <img :src="image.src" :alt="image.alt" :title="image.title">
            </div>
            <h3 class="skills__name"> {{image.id}}</h3>
          </div>
        </div>
      </div>



      <div class="skills__content">
        <h3 class="skills__title">
          <i class="ri-server-line"></i> {{ translate("desarrollo").back}}
        </h3>

        <div class="skills__info">
          <div class="skills__data" v-for="image in imagesBack">
            <div class="skills__blob">
              <img :src="image.src" :alt="image.alt" :title="image.title">
            </div>
            <h3 class="skills__name"> {{image.id}}</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<style>
</style>