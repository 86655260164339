<script setup>
import { onMounted } from 'vue';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import { translate } from '../../public/translations.js';

onMounted(() => {
  const swiperProjects = new Swiper('.projects__container', {
    loop: true,
    spaceBetween: 24,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
    },
    breakpoints: {
      1200: {
        slidesPerView: 1,
        spaceBetween: 24,
      },
    },
    mousewheel: false,
    keyboard: true,
  });
});
</script>


<template>
    <section class="projects section" id="projects">
      <h2 class="section__title">{{ translate("proyectos").nameProyecto }}</h2>
      <span class="section__subtitle">{{ translate("proyectos").nuestrosProyectos }}</span>
  
      <div class="container section__border">
        <div class="projects__container swiper">
          <div class="swiper-wrapper">
            
            <div class="projects__content swiper-slide">
              <img src="../../public/img/project5.png" alt="BlackPhone" class="projects__img" title="Página Web BlackPhone" />
              <div>
                <span class="projects__subtitle">Web</span>
                <h1 class="projects__title">BlackPhone</h1>
                <a href="https://blackphone.com.ar/" target="_blank" class="projects__button" aria-label="BlackPhone">
                  {{ translate("proyectos").verSitio }}<i class="ri-arrow-right-line"></i>
                </a>
              </div>
            </div>

            <div class="projects__content swiper-slide">
              <img src="../../public/img/project6.png" alt="Dra. Benítez Alelí" class="projects__img" title="Página Web Dra. Benítez Alelí" />
              <div>
                <span class="projects__subtitle">Web</span>
                <h1 class="projects__title">Dra. Benítez Alelí</h1>
                <a href="https://drabenitezaleli.corpresi.com/" target="_blank" class="projects__button" aria-label="Dra. Benítez Alelí">
                  {{ translate("proyectos").verSitio }}<i class="ri-arrow-right-line"></i>
                </a>
              </div>
            </div>
  
            <div class="projects__content swiper-slide">
              <img src="../../public/img/project4.png" alt="La Fortaleza" class="projects__img" title="Página Web La Fortaleza Distribuidora"/>
              <div>
                <span class="projects__subtitle">Web</span>
                <h1 class="projects__title">La Fortaleza</h1>
                <a href="https://lafortaleza.com.ar/" target="_blank" class="projects__button" aria-label="La Fortaleza Distribuidora">
                  {{ translate("proyectos").verSitio }}<i class="ri-arrow-right-line"></i>
                </a>
              </div>
            </div>
  
            <div class="projects__content swiper-slide">
              <img src="../../public/img/project3.png" alt="GAIR Instalaciones Industriales" class="projects__img" title="Página Web GAIR Instalaciones Industriales"/>
              <div>
                <span class="projects__subtitle">Web</span>
                <h1 class="projects__title">GAIR Instalaciones Industriales</h1>
                <a href="https://gairinstalaciones.com.ar/" target="_blank" class="projects__button" id="gair-slide" aria-label="GAIR Instalaciones Industriales" area-disabled="true">
                  {{ translate("proyectos").verSitio }}<i class="ri-arrow-right-line"></i>
                </a>
              </div>
            </div>
  
            <div class="projects__content swiper-slide">
              <img src="../../public/img/project2.png" alt="Aplicación de escritorio" class="projects__img" title="Aplicación de escritorio" />
              <div>
                <span class="projects__subtitle">App</span>
                <h1 class="projects__title">Desktop App</h1>
                <a href="#contact" class="projects__button" aria-label="Desktop App">
                  {{translate("tituloServicio").name}}<i class="ri-arrow-right-line"></i>
                </a>
              </div>
            </div>

            <div class="projects__content swiper-slide">
              <img src="../../public/img/project1.png" alt="DelSur Inmobiliaria" class="projects__img" title="Página Web DelSur Inmobiliaria"/>
              <div>
                <span class="projects__subtitle">Web</span>
                <h1 class="projects__title">DelSur Inmobiliaria</h1>
                <a href="https://inmobiliaria-delsur.com.ar/" target="_blank" class="projects__button" aria-label="DelSur Inmobiliaria">
                  {{ translate("proyectos").verSitio }}<i class="ri-arrow-right-line"></i>
                </a>
              </div>
            </div>

          </div>
  
          <div class="swiper-button-next">
            <i class="ri-arrow-right-s-line"></i>
          </div>
          <div class="swiper-button-prev">
            <i class="ri-arrow-left-s-line"></i>
          </div>
  
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </section>
  </template>
  
  
  <style scoped>
  #gair-slide {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
  </style>
  