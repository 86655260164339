<script>
import axios from "axios";
export default {
  data(){
    return {
      formData:{
        user_name:'',
        user_email:'',
        user_tel:'',
        user_project:''
      },
      message:'',
    };
  },
  methods:{
    async submitForm(){
      try {
        const response = await axios.post('https://api.corpresi.com/api/form/contact', this.formData);
        if(response.status === 201){
          this.formData.user_name= '';
              this.formData.user_email= '';
              this.formData.user_tel= '';
              this.formData.user_project= '';
        }
      }
      catch (error) {
        console.log("este es el catch", error);
      }
    }
  }
};
</script>
<script setup>
import {translate} from "../../public/translations.js";
</script>

<template>
<section class="contact section" id="contact">
            <h2 class="section__title">{{translate("tituloServicio").name}}</h2>
                <span class="section__subtitle">{{ translate("tituloServicio").content }}</span>

                <div class="contact__container container grid section__border">
                    <div class="contact__content">
                        <h3 class="contact__title">
                            <i class="ri-chat-2-line"></i> {{translate("hablanos").name}}
                        </h3>

                        <div class="contact__info">
                            <div class="contact__data">
                                <span class="contact__data-title">Email</span>
                                <span class="contact__data-info">resicorporation@gmail.com</span>
                            </div>

                            <div class="contact__data">
                                <span class="contact__data-title">WhatsApp / Telegram Máximo</span>
                                <span class="contact__data-info">+54 11 6880-3040</span>

                                <a href="https://api.whatsapp.com/send?phone=541168803040&text=&source=&data=&app_absent=" target="_blank" class="contact__button" aria-label="WhatsApp Máximo">
                                  {{ translate("escribenos").name }} <i class="ri-corner-down-left-line"></i>
                                </a>
                            </div>

                            <div class="contact__data">
                                <span class="contact__data-title">WhatsApp / Telegram Martín</span>
                                <span class="contact__data-info">+54 11 5159-9231</span>

                                <a href="https://api.whatsapp.com/send?phone=541151599231&text=&source=&data=&app_absent=" target="_blank" class="contact__button" aria-label="WhatsApp Martín">
                                  {{ translate("escribenos").name }} <i class="ri-corner-down-left-line"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="contact__content">
                        <h3 class="contact__title">
                            <i class="ri-mail-send-line"></i> {{ translate("escribenos").content }}
                        </h3>

                        <form action="" class="contact__form" id="contact-form" @submit.prevent="submitForm">
                            <div class="contact__form-div">
                                <label for="contact-name" class="contact__form-tag">{{translate("formulario").name}}</label>
                                <input v-model="formData.user_name" type="text" name="user_name" required :placeholder="translate('formulario').contentName" class="contact__form-input" id="contact-name">
                            </div>

                            <div class="contact__form-div">
                                <label for="contact-email" class="contact__form-tag">Email</label>
                                <input v-model="formData.user_email" type="email" name="user_email" required :placeholder="translate('formulario').contentEmail" class="contact__form-input" id="contact-email">
                            </div>

                            <div class="contact__form-div">
                                <label for="contact-telephone" class="contact__form-tag">{{translate("formulario").telefono}}</label>
                                <input v-model="formData.user_tel" type="tel" name="user_tel" required :placeholder="translate('formulario').contentTelefono" class="contact__form-input" id="contact-telephone">
                            </div>

                            <div class="contact__form-div contact__form-area">
                                <label for="contact-project" class="contact__form-tag">{{translate("formulario").proyecto}}</label>
                                <textarea v-model="formData.user_project" name="user_project" required :placeholder="translate('formulario').contentProyecto" id="contact-project" class="contact__form-input"></textarea>
                            </div>

                            <p class="contact__message" id="contact-message"></p>

                            <button type="submit" class="contact__button" aria-label="send">
                              {{translate("formulario").enviar}} <i class="ri-arrow-right-up-line"></i>
                            </button>
                        </form>
                    </div>
                </div>
            </section>
</template>


<style>
</style>